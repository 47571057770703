<template>
  <div
    class="slider-item card tile tile-vod"
    :data-title="title"
    :class="'uuid-' + uuid"
    :style="'background: url(' + miniPoster + '); background-size:cover'"
  >
    <template v-if="!showLoader">
      <ImageLazy :src="poster" :err="posterError" :alt="title" />

      <div class="card-content">
        <div class="card-img-overlay">
          <div class="card-options-top">
            <span
              v-if="!showGroupInfo && rating >= 0 && !blockByParentalControl"
              class="tplay-rating"
              :title="'T-Play rating: ' + rating"
            >
              <i class="zmdi zmdi-star" aria-hidden="true"></i>
              <small>{{ rating }}</small>
            </span>

            <a
              v-if="showMoreInfo"
              v-on:click.prevent="onMoreInfo()"
              href="#"
              class="card-action-secondary btn-showmodal"
              title="Más información"
            >
              <i class="zmdi zmdi-info-outline"></i>
            </a>

            <a
              v-if="
                (data.type === 'MOVIE' || data.type === 'SERIE') &&
                !blockByParentalControl
              "
              v-on:click.prevent="onFavorite()"
              href="#"
              class="card-action-secondary"
              :title="
                data.favorite
                  ? 'Quitar de mis favoritos'
                  : 'Agregar a mis favoritos'
              "
            >
              <i
                class="zmdi zmdi-favorite-outline"
                :class="{ 'zmdi-favorite': data.favorite }"
              ></i>
            </a>
          </div>

          <template>
            <a
              v-if="btnPlayState == 'pause-actual'"
              v-on:click.prevent="getPlayer().onControlPlayPause()"
              href="#"
              title="Pausar esto"
              class="btn btn-play"
            >
              <i class="zmdi zmdi-pause" aria-hidden="true"></i>
            </a>
            <a
              v-else-if="btnPlayState == 'play-actual'"
              v-on:click.prevent="getPlayer().onControlPlayPause()"
              href="#"
              title="Reproducir esto"
              class="btn btn-play"
            >
              <i class="zmdi zmdi-play" aria-hidden="true"></i>
            </a>
            <a
              v-else
              v-on:click.prevent="onPlay()"
              href="#"
              title="Reproducir esto"
              class="btn btn-play"
            >
              <i class="zmdi zmdi-play" aria-hidden="true"></i>
            </a>
          </template>

          <a
            v-if="showMoreInfo"
            v-on:click.prevent="onMoreInfo()"
            href="#more-info"
            class="more-info-all"
            title="Más información"
          ></a>

          <h5 class="card-title" :title="title">{{ shortTitle }}</h5>

          <p
            v-if="!showGroupInfo && !blockByParentalControl"
            class="card-text card-text--meta"
          >
            <span v-if="data.seasons" class="genre">{{ numberOfSeasons }}</span>

            <span
              v-if="data.certificateCode"
              class="certificate"
              :title="'Calificación: ' + data.certificate"
              >{{ data.certificateCode }}</span
            >
            <span class="year">{{
              data.year ? data.year : "&nbsp;&nbsp;&nbsp;&nbsp;"
            }}</span>
            <span v-if="data.duration" class="duration"
              >{{ data.duration }} min.</span
            >
          </p>

          <p v-if="showGroupInfo" class="card-text card-text--meta">
            <!-- <span v-if="data.chapters" class="genre">{{ numberOfChapters }}</span> -->
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

import { blockAsset } from "../helpers/parentalControl";


export default {
  name: "cardVOD",

  inject: ["getPlayer"],

  components: {
    ImageLazy: () => import("@/components/Image-lazy.vue"),
  },

  props: {
    data: {
      type: Object,
      default: {},
      required: true,
    },
    showMoreInfo: {
      type: Boolean,
      required: false,
      default: true,
    },
    showGroupInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    componentOrigin: {
      type: String,
      required: false,
      default: "",
    },
    zapflag: { type: Boolean, default: false },
    sliderId: {
      type: String,
      default: "",
      required: false,
    },
    sliderTitle: {
      type: String,
      default: "",
      required: false,
    },
    cardId: {
      type: String,
      default: 0,
      required: false,
    },
  },

  data() {
    return {
      dom: {
        cardConent: null,
        tile: null,
      },
      btnPlayState: null,
      uuid: tplay.generateUUID(),
      blockByParentalControl: false,
      assetData: null,
      capituloGeneral: null,
      indexSeason: 0,
      showLoader: true,
      oldValueParentalCOntrol: false,
      maxAgeCap: null,
      cpChangedStatus: 1
    };
  },

  created() {
    this.showLoader = true;
    this.assetData = this.data;
    if (this.data.type === "SERIE") {
      this.getDataAssetSerie();
    } else {
      this.showLoader = false;
    }

    //console.log('[MY-CONTENT]', this.sliderId, this.data);
  },

  mounted() {
    this.dom.tile = document.querySelector(".uuid-" + this.uuid + ".card.tile");

    this.dom.cardConent = this.dom.tile.querySelector(".card-img-overlay");

    this.dom.tile.addEventListener("mouseenter", this.onMouseEnter, false);
    this.dom.tile.addEventListener("mouseleave", this.onMouseLeave, false);

    //Escucho el cambio de estado del player
    this.$bus.$on("player:state-change", this.onPlayerStateChange);

    this.$bus.$on("parentalControl.state_changed", this.updateParentalControl);

    //Para que se inicialice el valor de btnPlayState
    this.onPlayerStateChange();

    // Control del control parental
    if (this.data.status !== false && this.data.type !== "SERIE") {
      this.blockByParentalControl =
        telecentro.tplay.core.biz.parentalControl.blockAsset(this.data);
    }
    if (this.data.status !== false && this.data.type === "CHAPTER_GROUP") {
      this.blockByParentalControl =
        telecentro.tplay.core.biz.parentalControl.blockAsset(
          this.data.chapters[0]
        );
    }

  },

  methods: {
    updateParentalControl() {
      //console.log('parentalControl.state_changed',this.cpChangedStatus);
      this.cpChangedStatus++;
    },
    onPlay() {
      const self = this;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: this.sliderId,
          rail_title: this.sliderTitle,
          card_id: this.cardId,
          screen: this.$route.name,
        },
        tplay.grafana.actions.ENTER
      );

      if (self.blockByParentalControl) {
        self.$bus.$emit("modal-pin:show", function () {
          _onPlay();
        });
      } else {
        _onPlay();
      }

      function _onPlay() {
        if (gtag) {
          gtag("event", "Reproducir desde card", {
            event_category: "CARD-VOD",
            event_action: "Reproducir desde card",
            event_label: self.title,
            value: "0",
          });
        }

        if (self.assetData.type === "CHAPTER_GROUP") {
          //Reproduzco el primer episodio
          self.$bus.$emit("player:set-source", self.assetData.chapters[0], {
            rail: {
              show: false,
            },
          });
        } else {
          self.$bus.$emit("player:set-source", self.assetData);
        }
        tplay.sendAudience(self.assetData, self.componentOrigin);
      }
      const butttonClose = document.querySelector(".close");
      butttonClose?.click();
      document.body.style.pointerEvents = 'auto';
    },

    onMoreInfo() {
      const self = this;

      const { isActive = null, tplayPlayer = null } = this.getPlayer();
      if ( isActive && isActive() && tplayPlayer && tplayPlayer.mode == "full" ) return;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: this.sliderId,
          rail_title: this.sliderTitle,
          card_id: this.cardId,
          screen: this.$route.path,
        },
        tplay.grafana.actions.ENTER
      );

      if (self.blockByParentalControl) {
        self.$bus.$emit("modal-pin:show", function () {
          _onMoreInfo();
        });
      } else {
        _onMoreInfo();
      }

      function _onMoreInfo() {
        if (self.assetData.type === "MOVIE") {
          self.$bus.$emit("modal-movie:show", {
            data: self.assetData,
            componentOrigin: self.componentOrigin,
          });
        } else if (self.assetData.type === "SERIE") {
          self.$bus.$emit("modal-serie:show", {
            data: self.assetData,
            componentOrigin: self.componentOrigin,
          });
        } else if (
          self.assetData.type === "CHAPTER_GROUP" ||
          self.assetData.type === "CHAPTER"
        ) {
          self.$bus.$emit("modal-group-vod:show", {
            data: self.assetData,
            componentOrigin: self.componentOrigin,
          });
        }
      }
    },

    onFavorite() {
      const self = this;

      if (self.blockByParentalControl) {
        self.$bus.$emit("modal-pin:show", function () {
          _onFavorite();
        });
      } else {
        _onFavorite();
      }

      function _onFavorite() {
        let asset = self.assetData;
        if (asset.type === "SERIE") {
          asset = asset.seasons[0].capitulos[0];
        }
        if (asset.favorite) {
          telecentro.tplay.core.favoritos.quitarFavoritos(asset, function () {
            asset.favorite = false;
            self.data.favorite = false;
          });
        } else {
          telecentro.tplay.core.favoritos.agregarFavoritos(
            asset,
            function () {
              asset.favorite = true;
              self.data.favorite = true;
            },
            //console.log
          );
        }
      }
    },

    onMouseEnter(event) {
      event.preventDefault();
      this.dom.tile.classList.add("hovering");
    },

    onMouseLeave(event) {
      event.preventDefault();
      this.dom.tile.classList.remove("hovering");
    },

    onPlayerStateChange() {
      if (
        this.getPlayer().isActive() &&
        this.getPlayer().isAsset(this.assetData.idAsset)
      ) {
        if (this.getPlayer().isPlaying()) {
          this.btnPlayState = "play-actual";
          //tplay.sendAudience(this.dataAsset, self.componentOrigin);
        } else {
          this.btnPlayState = "pause-actual";
          //tplay.sendAudience(this.dataAsset, 'PAU');
        }
      } else {
        this.btnPlayState = "play-otro";
      }
    },

    getCapituloGeneral() {
      if (
        !this.assetData.seasons ||
        this.assetData.seasons.length === 0 ||
        !this.assetData.seasons[0].capitulos ||
        this.assetData.seasons[0].capitulos.length === 0
      ) {
        return null;
      }

      return this.assetData.seasons[0].capitulos[0];
    },

    getDataAssetSerie() {
      const self = this;
      //Cargo la primera temporada
      self.selectSeason(0, function () {
        if (self.capituloGeneral) {
          //Como la serie no tiene un rango de edad, uso el rango máximo de todos los episodios de la temporada actual:
          self.maxAgeCap = self.capituloMaxAge(
            self.assetData.seasons[self.indexSeason].capitulos
          );

          self.assetData.certificate = self.maxAgeCap.certificate;
          self.assetData.certificateCode = self.maxAgeCap.certificateCode;

          //Uso el primer episodio para el rating y el favoriteado de la serie.
          //No bloqueo la interfaz para esto.
          telecentro.tplay.core.vod.obtenerScore(
            self.capituloGeneral,
            function () {
              self.assetData.favorite = self.capituloGeneral.favorite;
              self.assetData.rating = self.capituloGeneral.rating;
              self.assetData.userRating = self.capituloGeneral.userRating;
            }
          );
          self.blockByParentalControl =
            telecentro.tplay.core.biz.parentalControl.blockAsset(self.maxAgeCap);
          self.showLoader = false;
        }
        self.showLoader = false;
      });
    },

    selectSeason(indexSeason, onFinish) {
      const self = this;

      self.indexSeason = indexSeason;

      // Traigo los episodios de la temporada.
      //El assetSerie devuelto por traerCapitulos es el mismo objeto (la misma referencia) que el que se le pasa.
      telecentro.tplay.core.series.traerCapitulos(
        self.data,
        indexSeason,
        function (assetSerie) {
          self.assetData = assetSerie;

          const season = self.assetData.seasons[indexSeason];

          if (!season) return;

          self.capituloGeneral = self.getCapituloGeneral();

          if (typeof onFinish === "function") {
            onFinish();
          }
        }
      );
    },

    //Devuelve el episodio que tenga el máximo de edad (campo csasname)
    capituloMaxAge(capitulos) {
      let maxAge = -1;
      let maxAgeCap = null;

      for (let c = 0, cl = capitulos.length; c < cl; c++) {
        const age = parseInt(capitulos[c].csasname);

        if (age > maxAge) {
          maxAge = age;
          maxAgeCap = capitulos[c];
        }
      }

      return maxAgeCap;
    },
  },

  computed: {
    rating() {
      return this.capituloGeneral?.rating ?? this.data.rating;
    },

    shortTitle() {
      if (this.blockByParentalControl) {
        return "Bloqueado";
      } else {
        const limite = 32;
        return this.title.length <= limite
          ? this.title
          : this.title.substr(0, limite - 3) + "...";
      }
    },

    title() {
      if (this.blockByParentalControl) {
        return "Bloqueado";
      } else {
        const te = [
          this.data.n_temporada ? "T" + this.data.n_temporada : null,
          this.data.n_episodio ? "E" + this.data.n_episodio : null,
        ]
          .filter((x) => x)
          .join(":");

        const title =
          te +
          " " +
          (this.data.type === "CHAPTER_GROUP"
            ? this.data.serie_name
            : this.data.title);
        return telecentro.tplay.core.helpers.string.replaceUnicode(
          title.trim()
        );
      }
    },

    numberOfSeasons() {
      const numberOfSeasons = this.data.seasons.length;

      return numberOfSeasons > 1
        ? numberOfSeasons + " temporadas"
        : numberOfSeasons + " temporada";
    },

    numberOfChapters() {
      const numberOfChapters = this.data.chapters.length;

      return numberOfChapters > 1
        ? numberOfChapters + " episodios favoritos"
        : numberOfChapters + " episodio favorito";
    },

    posterError() {
      return window.location.origin + "/assets/images/no-poster-5-7.png";
    },

    poster() {
      const urlFromAlpha = this.data.poster
        ? this.data.poster.split("/").pop()
        : "";

      if (this.blockByParentalControl || urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return window.location.origin + "/assets/images/no-poster-5-7.png";
      } else if (this.data.poster && this.data.poster !== "") {
        //La url del poster ya viene seteada en el objeto
        return this.data.poster;
      } else if (this.data.type === "MOVIE") {
        return telecentro.tplay.core.api.traerPosterHWPeliculas(
          this.data.idAsset,
          263,
          175
        );
      } else if (this.data.type === "SERIE") {
        return telecentro.tplay.core.api.traerPosterHWSeries(
          this.data.idAsset,
          263,
          175
        );
      } else if (this.data.type === "CHAPTER_GROUP") {
        return telecentro.tplay.core.api.traerPosterHWSeries(
          this.data.idSerie,
          263,
          175
        );
      } else {
        //Genérico
        return telecentro.tplay.core.api.traerPoster(this.data.idAsset);
      }
    },

    miniPoster() {
      if (this.blockByParentalControl) {
        return window.location.origin + "/assets/images/no-poster-5-7.png";
      } else if (this.data.type === "MOVIE") {
        return telecentro.tplay.core.api.traerPosterHWPeliculas(
          this.data.idAsset,
          1,
          1
        );
      } else {
        return telecentro.tplay.core.api.traerPosterHWSeries(
          this.data.idAsset,
          1,
          1
        );
      }
    },
  },

  beforeDestroy() {
    this.dom.tile.removeEventListener("mouseenter", this.onMouseEnter, false);
    this.dom.tile.removeEventListener("mouseleave", this.onMouseLeave, false);

    this.$bus.$off("player:state-change", this.onPlayerStateChange);

    this.$bus.$off("parentalControl.state_changed", this.updateParentalControl);
  },

  watch: {
    // blockByParentalControl:function(newValue, oldValue){
    //     localStorage.setItem('oldvalueParentalControl',oldValue);
    //     localStorage.setItem('newvalueParentalControl',newValue);
    //     if (oldValue && oldValue === false) {
    //         this.oldValueParentalCOntrol = true;
    //     } else {
    //         this.oldValueParentalCOntrol = false;
    //     }
    // },
    // zapflag:function(newvalue, oldvalue){
    //     if (newvalue === true) {
    //         if (this.oldValueParentalCOntrol === true) {
    //             this.blockByParentalControl = localStorage.getItem('oldvalueParentalControl');
    //         } else {
    //             this.blockByParentalControl = localStorage.getItem('newvalueParentalControl');
    //         }
    //     }
    // }
    cpChangedStatus: function(newValue, oldValue){
      // Control del control parental
      if (this.data.status !== false && this.data.type !== "SERIE" && this.data.type != "CHAPTER_GROUP") {
        this.blockByParentalControl =
          telecentro.tplay.core.biz.parentalControl.blockAsset(this.data);
      } else if ((this.data.status !== false || this.data.status === undefined) && this.data.type === "CHAPTER_GROUP") {
        this.blockByParentalControl =
          telecentro.tplay.core.biz.parentalControl.blockAsset(
            this.data.chapters[0]
          );
      } else if (this.data.status !== false && this.data.type === "SERIE"){
        this.blockByParentalControl =
            telecentro.tplay.core.biz.parentalControl.blockAsset(this.maxAgeCap);
      }
    } 
  },
};
</script>
